import {CheckApiError, GetGeneric} from '../common/teide';
import React, {useState} from 'react';
import ArtistDetails, {ArtistSummary} from '../components/ArtistDetails';
import {useNavigate} from 'react-router-dom';
import PleaseWaitButton from '../components/PleaseWaitButton';

interface ErrorDetails
{
    error: string
    detail: string
}

export default function ArtistList()
{
    const navigate = useNavigate();

    const [data, setData] = useState<ArtistSummary[] | ErrorDetails>();

    async function LoadArtists()
    {

        const results = await GetGeneric('/Artists/GetFollowedArtist');
        if (CheckApiError(navigate, results)) return;
        setData(results);
    }

    if (data === undefined) return <div className='p-3'>
        <PleaseWaitButton buttonText='View My Artists' className='btn btn-primary' onClick={LoadArtists} waitText='Please Wait, loading'></PleaseWaitButton>
    </div>

    const allartists = data as ArtistSummary[];
    const ret: JSX.Element[] = [];
    allartists.sort((a, b) =>
    {
        return (b.rating - a.rating);
    });
    allartists.forEach(k =>
    {
        ret.push(<ArtistDetails key={k.id} data={k}></ArtistDetails>);
    });
    return <>
        <div className='scroll-header row'>
            <div className='artist-cover col-auto'></div>
            <div className='col my-auto'>Artist</div>
            <div className='col-1 my-auto d-none d-lg-block'>Artwork</div>
            <div className='col-1 my-auto d-none d-lg-block'>Followers</div>
            <div className='col-4 my-auto col-lg-3'>Stars</div>
            <div className='col-1 my-auto col-lg-1'></div>
            
        </div>
        
        <div className='row'>
            <div className='scrollable-with-header'>{ret}</div>
        </div>
    </>
}

/*        <div className="artist-cover col-1 my-auto"><img alt="" src={props.data.image_url} ></img></div>
<div className="artist-name col-5 col-lg-4 my-auto">{props.data.name}</div>
<div className="artist-artwork-count col-1 my-auto d-none d-lg-block">{props.data.artwork}</div>
<div className="artist-follower-count col-1 my-auto d-none d-lg-block">{useTotal}</div>
<div className="artist-stars col-4 col-lg-3 my-auto"><StarRating starCount={7} stars={currentStars} onRatingChanged={ChangeRating} /></div>
<button onClick={ShowAlbums} className="artist-albums col-2 my-auto button">Albums...</button>
*/