import React, {useEffect, useRef, useState} from "react";
import {CheckApiError, GetGeneric, PostGeneric} from "../common/teide";
import {useNavigate} from "react-router-dom";
import SimplePrompt from "../components/SimplePrompt";
import UserGenreLine, {IGenreChange} from "../components/UserGenreLine";

interface GenreInfo
{
    genreName: string
    artistIds: string[]
    isPublic: boolean
}

interface GenreReturn
{
    allUserGenres: GenreInfo[]
    artistLookup: {[key: string]: string}
}

export default function GenreManagement()
{
    const navigate = useNavigate();
    const [data, setData] = useState<GenreReturn>();
    const [newGenres, setNewGenres] = useState<string[]>([]);
    const [allUpdates, setAllUpdates] = useState<IGenreChange[]>([]);
    useEffect(() =>
    {
        DoWork();
    }, [])

    async function DoWork()
    {
        const results = await GetGeneric('/Artists/GetUserGenres')
        if (CheckApiError(navigate, results)) return;
        setData(results);
    }

    const dialog = useRef<HTMLDialogElement>(null);

    function newGenre()
    {
        if (dialog.current == null) return;
        dialog.current.showModal()
    }


    async function ProcessUpdate(value: IGenreChange[])
    {
        const artists = value.map(m => m.artistId);
        const main = value[0];
        console.log(`on ${main.genreName} we're ${main.action}-ing ${artists.join(' ; ')}`);
        if (main.action === "add") {
            const body = {'genreName': main.genreName, 'artistIds': artists};
            await PostGeneric(`/Artists/AddArtistsToUserGenre`, JSON.stringify(body));
            console.log(`added ${JSON.stringify(body)}`);
        }
        if (main.action === "remove") {
            const body = {'genreName': main.genreName, 'artistIds': artists};
            await PostGeneric(`/Artists/RemoveArtistsFromUserGenre`, JSON.stringify(body));
            console.log(`removed ${JSON.stringify(body)}`);
        }
    }

    async function submitChanges()
    {
        const groupedItems = Object.groupBy(allUpdates, ({action, genreName}) => JSON.stringify({action, genreName}));
        if (groupedItems === undefined) return;
        Object.entries(groupedItems).forEach(k =>
        {
            if (k[1] === undefined) return;
            ProcessUpdate(k[1]);
        })
    }

    function newGenreSelected(name: string)
    {
        const clonedGenres: string[] = JSON.parse(JSON.stringify(newGenres));
        clonedGenres.push(name);
        setNewGenres(clonedGenres);
        closeDialog();
    }
    function closeDialog()
    {
        if (dialog.current == null) return;
        dialog.current.close();
    }

    function GenreChanged(modifier: IGenreChange)
    {
        const existing = allUpdates.filter(_ => _.artistId == modifier.artistId && _.genreName === modifier.genreName);
        if (existing.length > 1) throw Error("Should never have more than 1 update for an artist");
        if (existing.length == 1) {
            if (modifier.action !== existing[0].action) {
                // remove the existing item
                const newList = allUpdates.filter(_ => !(_.artistId == modifier.artistId && _.genreName === modifier.genreName));
                setAllUpdates(newList);
            }
            return;
        }
        const cloned: IGenreChange[] = JSON.parse(JSON.stringify(allUpdates));
        cloned.push(modifier);
        setAllUpdates(cloned);
    }

    if (data !== undefined) {
        const genres: JSX.Element[] = [];
        data.allUserGenres.forEach((gi) =>
        {
            genres.push(<UserGenreLine onChange={GenreChanged} key={gi.genreName} genreName={gi.genreName} artistIds={gi.artistIds} artistLookup={data.artistLookup} ></UserGenreLine>)
        });
        newGenres.forEach(g =>
        {
            genres.push(<UserGenreLine onChange={GenreChanged} key={g} genreName={g} artistIds={[]} artistLookup={data.artistLookup} ></UserGenreLine>)
        })

        return <div>
            <div>
                {genres}
            </div>
            <div className="row">
                <button onClick={newGenre} className="mx-2 col-auto btn btn-primary">New Genre...</button>
                <div className="col"></div>
                <button onClick={submitChanges} className="mx-2 col-auto btn btn-success">Submit Changes</button>
            </div>
            <dialog className="dialog-container" ref={dialog}>
                <SimplePrompt okClicked={newGenreSelected} cancelClicked={closeDialog} prompt="Genre Name" description="Please enter the name of the User Defined Genre"></SimplePrompt>
            </dialog>
        </div>
    }
    return <div>Please wait</div>
}