import React, {useState} from "react"
import HorizontalRangeSelector, {IHorizontalItem} from "./HorizontalRangeSelector"
import {CheckApiError, DeleteGeneric, PostGeneric} from "../common/teide";

export interface IPlayListItem
{
    id: string
    name: string
    totalSongs: number
    attribute: PlaylistItemType
    isSelected?: boolean
}

type PlaylistItemType = 'songs' | 'normal' | 'albums' | 'good';

interface IProps
{
    item: IPlayListItem
}

export default function PlaylistItem(props: IProps)
{
    const [selected, setSelected] = useState<boolean>(false);
    const [status, setStatus] = useState<PlaylistItemType>(props.item.attribute);

    async function modifyStatus(key: string, value: string)
    {
        if (value === props.item.attribute) return;
        setStatus(value as PlaylistItemType);
        let result;
        if (value === 'normal') {
            result = await DeleteGeneric('/PlayLists/RemoveAttributePlaylist/' + props.item.id, null);
        }
        else {
            let attribute: string;
            if (value === 'bad-album') attribute = 'albums';
            else if (value === 'bad') attribute = 'songs';
            else if (value === 'good') attribute = 'good';
            else throw new Error('unknown value');
            result = await PostGeneric('/PlayLists/SetAttributePlaylist/' + props.item.id + '?attribute=' + attribute, null);
        }
        CheckApiError(undefined,result);
    }

    function ClickCheck()
    {
        const newSelected = !selected;
        props.item.isSelected = newSelected;
        setSelected(newSelected);
    }

    const items: IHorizontalItem[] = [
        {key: 'normal', selected: status === 'normal', text: 'Normal'},
        {key: 'bad', selected: status === 'songs', text: 'Bad Songs'},
        {key: 'bad-album', selected: status === 'albums', text: 'Bad Album'},
        {key: 'good', selected: status === 'good', text: 'Good Songs'}
    ]

    const range = <HorizontalRangeSelector multiSelect={false} items={items} name="pl-type" onChange={modifyStatus}></HorizontalRangeSelector>

    // const badButton = props.isBad ?
    //     <PleaseWaitButton justButton={true} buttonText="Bad" className="btn btn-info m-2" onClick={NotBadPlaylist} waitText="Processing"></PleaseWaitButton> :
    //     <PleaseWaitButton justButton={true} buttonText="Bad Songs" className="btn btn-primary m-2" onClick={BadPlaylist} waitText="Processing"></PleaseWaitButton>;

    //     const badAlbumButton = props.isBadAlbum ?
    //     <PleaseWaitButton justButton={true} buttonText="Bad" className="btn btn-info m-2" onClick={NotBadPlaylistAlbum} waitText="Processing"></PleaseWaitButton> :
    //     <PleaseWaitButton justButton={true} buttonText="Bad Albums" className="btn btn-primary m-2" onClick={BadPlaylistAlbum} waitText="Processing"></PleaseWaitButton>;

    return <>
        <div className="row artist-row d-lg-none">
            <div className="col-1 text-center my-auto"><input className="big-check p-0 m-0" type='checkbox' checked={selected} onChange={ClickCheck}></input></div>
            <div className="col-10 my-auto">{props.item.name}</div>
            <div className="col-1 my-auto">{props.item.totalSongs}</div>
            <div className="col-12 text-end my-auto p-0">{range}</div>
        </div>
        <div className="row artist-row d-none d-lg-flex">
            <div className="col-1 text-center my-auto"><input className="big-check p-0 m-0" type='checkbox' checked={selected} onChange={ClickCheck}></input></div>
            <div className="col-1 my-auto">{props.item.totalSongs}</div>
            <div className="col-auto my-auto">{props.item.name}</div>
            <div className="col text-end my-auto p-0">{range}</div>
        </div>
    </>
}