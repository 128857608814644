import {useLocation} from "react-router-dom";
import React from 'react';


export default function ErrorInfo(/*props : IProps*/)
{
    const location = useLocation();
    let errorInfo = location.state.err as string;
    if (errorInfo==='invalid_grant') errorInfo="Unable to connect to Spotify, this might be because your access has been revoked, or you haven't approved Spotify yet";
    return <div className="container-flow">
        <div className="row">
            <div className="col">
            <h1>{errorInfo}</h1>
            </div>
        </div>
    </div>
}