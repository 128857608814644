import {CheckApiError, GetGeneric} from '../common/teide';
import React, {useState} from 'react';
import ArtistDetailsProposed from '../components/ArtistDetailsProposed';
import {useNavigate} from 'react-router-dom';
import {ArtistSummary} from '../components/ArtistDetails';
import PleaseWaitButton from '../components/PleaseWaitButton';

export default function Recommended()
{
    const navigate = useNavigate();
    const [data, setData] = useState<ArtistSummary[]>();
    async function DoWork()
    {
        const results = await GetGeneric('/Artists/GetProposedArtists')
        if (CheckApiError(navigate, results)) return;
        setData(results);
    }

    if (data == null) return <div className='row'>
        <div className='col-1'></div>
        <div className='col py-3 d-flex justify-content-center'>
            <PleaseWaitButton use3dAnimation={true} buttonText='View My Recommended Artists' className='btn btn-primary' onClick={DoWork} waitText="Please wait while we build your list of artists, this can take a while if it's the first time you've run this"></PleaseWaitButton>
            </div>
            <div className='col-1'></div>
    
    </div>
    else {
        const ret = [];
        for (const k in data) {
            const dets = data[k];
            ret.push(<ArtistDetailsProposed key={dets.id} data={dets}></ArtistDetailsProposed>);
        }
        return <>
            <div className='scroll-header row'>
                <div className='col-1'></div>
                <div className='col-6 my-auto'>Artist</div>
                <div className='col-1 my-auto'>Followers</div>
                {/* <div className='col-4 my-auto col-lg-3'>Stars</div> */}
            </div>
            <div className='row'>
                <div className='scrollable-with-header'>
                    {ret}
                </div>
            </div>
        </>

    }
}