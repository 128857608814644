import React, {useRef} from 'react'

interface IProps
{
    prompt: string
    description: string
    okClicked: (value: string)=>void
    cancelClicked: ()=>void
}

export default function SimplePrompt(props: IProps)
{
    function okButton()
    {
        console.log("howdy");
        if (prompt.current==null) return;
        props.okClicked(prompt.current.value);
    }
    function cancelButton()
    {
        console.log("doodgy");
        props.cancelClicked();
    }
    const prompt = useRef<HTMLInputElement>(null);

    return <div className='container-fluid dialog-inner-container g-0'>
        <div className='row g-0 dialog-header'>
            <div className='col'>
                {props.description}
            </div>
        </div>
        <div className='row g-0 dialog-prompt'>
            <div className='col'>
                <span>{props.prompt}</span>
                <input ref={prompt}></input>
            </div>
        </div>
        <div className='row g-0 dialog-buttons'>
            <div className='col'></div>
            <button onClick={cancelButton} className='mx-1 col-auto btn btn-danger'>Cancel</button>
            <button onClick={okButton} className='mx-1 col-auto btn btn-primary'>OK</button>
        </div>
    </div>
}