import React from "react";
import HorizontalRangeSelector, {IHorizontalItem} from "./HorizontalRangeSelector";

interface IProps
{
    name: string
    default: 'only' | 'exclude' | 'all'
    onChange: (name: string,selectedItems: string)=>void
}

export default function TriState(props: IProps)
{
    function BubbleUp(name: string,selectedItems: string)
    {
        const useSelected=selectedItems==='all'?'':selectedItems;
        props.onChange(name,useSelected);
    }

    const items:IHorizontalItem[] = [
        {key:'only',selected: (props.default=='only'),text:"Only"},
        {key:'exclude',selected: (props.default=='exclude'),text:"Exclude"},
        {key:'all',selected: (props.default=='all'),text:"All"}
    ]
    return <>
        <HorizontalRangeSelector name={props.name} onChange={BubbleUp} items={items} multiSelect={false}></HorizontalRangeSelector>
    </>
    
}