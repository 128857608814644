import React, {useEffect, useState} from "react"

interface IProps
{
    items: IHorizontalItem[]
    multiSelect: boolean
    onChange: (name: string, selectedItems: string) => void
    name: string
    clearButtonText?: string
    itemLimit?: {max: number, showAllButton: boolean}
}

export interface IHorizontalItem
{
    key: string
    text: string
    selected: boolean
    tooltip?: string
    className?: string
}
export function GetRangeSelectorValue(useItems: IHorizontalItem[]): string
{
    return useItems.filter(e => e.selected).map(e => e.key).join(';');
}

export default function HorizontalRangeSelector(props: IProps)
{
    const hardMax = 99999;
    const [items, setItems] = useState<IHorizontalItem[]>(props.items)
    const useMax = props.itemLimit!==undefined ? props.itemLimit?.max : hardMax;
    const [maxItems, setMaxItems] = useState<number>(useMax);

    useEffect(() =>
    {
        const current = GetRangeSelectorValue(items);
        props.onChange(props.name, current)
    }, [])

    async function onSelection(event: React.MouseEvent<HTMLDivElement, MouseEvent>, keySelected: string)
    {
        event.stopPropagation();
        event.preventDefault();
        const newItems = items.map(e =>
        {
            if (props.multiSelect) {
                if (e.key === keySelected) e.selected = !e.selected;
            }
            else {
                if (e.key === keySelected) e.selected = !e.selected;
                else e.selected = false;
            }
            return e;
        });
        const ret = GetRangeSelectorValue(newItems);
        props.onChange(props.name, ret);
        setItems(newItems); // force the refresh
    }

    function ClearSelection()
    {
        const newItems = items.map(e =>
        {
            e.selected = false;
            return e;
        }
        );
        setItems(newItems); // force the refresh
        const ret = GetRangeSelectorValue(newItems);
        props.onChange(props.name, ret);
    }

    function switchMax()
    {
        if (props.itemLimit===undefined) return;
        setMaxItems(maxItems === hardMax ? props.itemLimit.max : hardMax);
    }

    let anotherButton = null;
    if (props.itemLimit!==undefined && props.itemLimit.showAllButton) 
    {
        anotherButton = <div className="col-auto"><div className='btn btn-primary' onClick={switchMax}> {maxItems === hardMax ? 'Show Fewer...' : 'Show All...'}</div></div>
    }
    let buttonArea = null;
    if (props.clearButtonText !== undefined) {
        buttonArea = <div className="row my-2">
            <div className="col-auto">
                <button className="btn btn-primary" onClick={ClearSelection}>{props.clearButtonText}</button>
            </div>
            {anotherButton}
        </div>
    }
    else {
        buttonArea = anotherButton;
    }

    const display = items.slice(0,maxItems).map(e =>
    {
        let useClass = "horizontal-item";
        if (e.className !== undefined) useClass += " " + e.className;
        if (e.selected) useClass += " selected";
        return <div data-toggle="tooltip" data-html="true" data-placement="top" title={e.tooltip} className={useClass} onClick={(event) => onSelection(event, e.key)} key={e.key} >{e.text}</div>
    });
    return <div>{display}{buttonArea}</div>

}