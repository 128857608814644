import React, {useEffect, useState} from 'react';
import {CheckApiError, GetGeneric, GetLoggedInStatus} from '../common/teide';
import "../css/Progress.css"
import ProgressIndicator, {ProgressIcons} from '../components/ProgressIndicator';

type UserInfo =
    {
        display_name: string
        id: string
        email: string
        ref: string
        followed_artists: number
        with_ratings: number
        artwork_count: number
    }

export default function Welcome()
{
    const [user, setUser] = useState<UserInfo>();

    const currentLoginStatus = GetLoggedInStatus();

    useEffect(() =>
    {
        if (currentLoginStatus.spotifyLinked) {
            GetGeneric('/Users/Summary').then((e) =>
            {
                if (CheckApiError(undefined,e))
                {
                    return;
                }
                setUser(e)
            });
        }
    }, []);

    const allItems: JSX.Element[] = [];
    let count = 1;
    function AddItem(header: string, text: string)
    {
        allItems.push(<li key={count}><br></br><h3 className='welcome-header'>{header}</h3>
            <h5 className='ps-2 welcome-details'>{text}</h5>
        </li>)
        count++;
    }
    if (!currentLoginStatus.loggedIn) AddItem('Next Step',"Create an account or login. Select from the menu on the top left. Once you've registered, you can then link your account to Spotify");
    if (currentLoginStatus.loggedIn && !currentLoginStatus.spotifyLinked) AddItem('Next Step',"Click on 'Link To Spotify' in the top left menu, you can then start managing your Spotify content from here");
    AddItem('Generate Playlists', 'This is the key feature, it will scan all of your artists and choose random tracks based on the criteria you choose. You can choose the genre, the release date, the length of the song plus many more options, this lets you create playlists for every occasion');
    AddItem('View and manage your existing playlists', 'Allows you to mark certain playlists as \'bad\', which gives you the option to exclude certain tracks from generated playlist. As an example, you can have a playlist called \'rubbish\' then if you\'re listening to a track in Spotify and don\'t like it, you can add it to this list, it will then be ignored when generating playlists');
    AddItem('Give your followed artists a rating', 'Adding a rating to each artist will let us be more accurate when building playlists, we will automatically choose songs that are from your higher rated artists, and even exclude artists all together if they only have 1 star');
    AddItem('Follow New Artists', 'Based on the artists you already like, your liked songs, and your playlists, we can recommend new artists for you to follow. The more artists your follow, the more diverse your playlists will be');
    //AddItem('Create Artwork', 'Generate an image which comprises many of your artists\' album covers, it throws them together in a nice grid, which you can add as your desktop on your computer (if people still do that)');
    AddItem('VR Walkthrough', 'If you have a VR headset, you can explore your artists in a virtual world (experimental)');
    AddItem('Collaborative Playlists', 'Create playlists based on multiple listeners\' favourites, get ready for that road trip or party (coming soon)');
    AddItem('Import/Export', 'Export/Import your Spotify configuration, including playlists, liked songs/artists etc. (coming soon)');
    if (currentLoginStatus.loggedIn) AddItem('Progress', '');

    type PIProps = {
        info: string
        icon: ProgressIcons
        title: string
        color?: string
    }

    function GetRatingIcon(user: UserInfo | undefined): ProgressIcons
    {
        if (user !== undefined) {
            const pc = Math.round((user.with_ratings * 100) / user.followed_artists);
            if (pc === 100) return 'check';
            if (pc > 90) return 'emoji-neutral';
        }
        return 'x'
    }
    function GetArtworkIcon(user: UserInfo | undefined): ProgressIcons
    {
        if (user !== undefined) {
            const pc = (user.artwork_count / user.followed_artists) * 100;
            if (pc > 80) return 'check';
            if (pc > 40) return 'emoji-neutral';
        }
        return 'x'
    }

    let userInfo;
    let progress;
    if (currentLoginStatus.loggedIn) {
        if (user !== undefined) {
            userInfo = <table className='spotify-info'>
                <tbody>
                <tr>
                    <td>LTMP Username</td>
                    <td>{currentLoginStatus.username}</td>
                </tr>
                <tr>
                    <td>Spotify Display Name</td>
                    <td>{user.display_name}</td>
                </tr>
                <tr>
                    <td>External Link</td>
                    <td>{user.ref}</td></tr>
                <tr>
                    <td>Spotify Email</td>
                    <td>{user.email}</td>
                </tr>
                </tbody>
            </table>
        }
        else{
            userInfo= <table className='spotify-info'>
            <tbody>
            <tr>
                <td>LTMP Username</td>
                <td>{currentLoginStatus.username}</td>
            </tr>
            </tbody>
        </table>
        }
        const linkSpotify: PIProps = {icon: currentLoginStatus.spotifyLinked ? 'check' : 'x', info: "", title: 'Link Spotify'}
        const linkFollowed: PIProps = {
            title: 'Follow Artists',
            icon: user === undefined ? 'x' : user.followed_artists > 15 ? 'check' : user.followed_artists > 5 ? 'emoji-neutral' : 'x',
            info: user === undefined ? '' : user.followed_artists.toString() ?? '',
        }
        const linkRated: PIProps = {
            title: 'Rate Artists',
            icon: GetRatingIcon(user),
            info: user === undefined ? '' : Math.round((user.with_ratings * 100) / user.followed_artists).toString() + '%'
        }
        const linkArtwork: PIProps = {
            title: 'Like Artwork',
            icon: GetArtworkIcon(user),
            info: user === undefined ? '' : user.artwork_count.toString()
        }
        progress = <div className='row row-cols-auto'>
            <div className='col p-1'><ProgressIndicator info='' icon='check' title='Sign Up' color='green'></ProgressIndicator></div>
            <div className='col p-1'><ProgressIndicator {...linkSpotify}></ProgressIndicator></div>
            <div className='col p-1'><ProgressIndicator {...linkFollowed}></ProgressIndicator></div>
            <div className='col p-1'><ProgressIndicator {...linkRated} title='Rate Artists'></ProgressIndicator></div>
            <div className='col p-1'><ProgressIndicator {...linkArtwork}></ProgressIndicator></div>
        </div>
    }
    return <div className='row'>
        <div className='scrollable'>
            <h3><br></br>Welcome to Let The Music Play</h3>
            <ul className='welcome-list'>
                {allItems}
            </ul>
            {progress}
            <br></br>
            {userInfo}
        </div>
    </div>
}