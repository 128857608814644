import {useNavigate} from 'react-router-dom';
import {GetGeneric, CheckApiError, DeleteGeneric} from '../common/teide';
import React, {useRef, useState} from 'react';
import PleaseWaitButton from '../components/PleaseWaitButton';
import PlaylistItem, {IPlayListItem} from '../components/PlaylistItem';

export default function PlaylistList()
{
    const [data, setData] = useState<IPlayListItem[]>();
    const parentRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    async function loadData()
    {
        const playListList = await GetGeneric('/PlayLists/Index');
        if (CheckApiError(navigate, playListList)) return;
        if (playListList != null) {
            setData(playListList);
        }
    }

    async function deleteSelected()
    {
        const toDelete: string[] = [];
        if (data === undefined) return;
        data.forEach(e =>
        {
            if (e.isSelected) toDelete.push(e.id);
        })
        const body = JSON.stringify(toDelete);
        const deleteResult = await DeleteGeneric('/PlayLists/DeletePlaylist', body);
        if (deleteResult !== null) {
            const newData = data.filter(e => !e.isSelected);
            setData(newData);
        }
    }

    if (data === undefined) {
        return (
            <div ref={parentRef} className='m-3'>
                <PleaseWaitButton buttonText='Show My Playlists' className='btn btn-primary' onClick={loadData} waitText='Please wait'></PleaseWaitButton>
            </div>
        )
    }
    else {
        const items =
            data.map(element =>
            {
                return <PlaylistItem key={element.id} item={element}></PlaylistItem>
            });
        return <>
            <div className="scroll-header row d-none d-lg-flex">
                <div className="col-1"></div>
                <div className="col-1">Tracks</div>
                <div className="col-9">Playlist Name</div>
            </div>

            <div className='row'>
                <div className='scrollable-with-header-footer'>
                    {items}
                </div>
            </div>
            <br></br>
            <div ref={parentRef}>
                <PleaseWaitButton finishedAction='revert' buttonText='Delete Selected Playlist(s)' waitText='Please Wait' className='btn btn-primary' onClick={deleteSelected}></PleaseWaitButton>
            </div>
        </>

    }
}


