import { CheckApiError, PutGeneric } from "../common/teide";
import React, { useState } from "react";
import {ArtistSummary} from "./ArtistDetails";
import {useNavigate} from "react-router-dom";
//import PropTypes from 'prop-types';

export interface IProps{
    data: ArtistSummary
}

export default function ArtistDetailsProposed(props: IProps)
{
    const navigate = useNavigate();
    const [isUpdated,setIsUpdated] = useState(false);
    async function OnFollowArtist()
    {
        setIsUpdated(true);
        const artistId = props.data.id;
        const result = await PutGeneric('/Artists/FollowArtists',JSON.stringify([artistId]));
        CheckApiError(navigate,result);
    }

    let useTotal = 0;
    if (props.data.follower_count !== undefined) useTotal = props.data.follower_count;
    const useimg = props.data.image_url;
    let useClassName="btn btn-primary";
    if (isUpdated) useClassName="btn-secondary disabled";
    const useButton = isUpdated ? <div>Added</div> : <button className={useClassName} onClick={OnFollowArtist}>Follow Artist</button>;
    return <div className="row artist-row" key={props.data.id} id={props.data.id}>
        <div className="artist-cover col-1 my-auto"><img alt="" src={useimg} ></img></div>
        <div className="col-6 my-auto">{props.data.name}</div>
        <div className="col-2 my-auto">{useTotal}</div>
        <div className="col-3 my-auto">{useButton}</div>
    </div>
}