import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {CheckApiError, GetGeneric} from '../common/teide';

export default function ConfirmSpotify()
{
    const navigate = useNavigate();
    const [searchPara] = useSearchParams();
    const [complete, setComplete] = useState<boolean>(false);
    const code = searchPara.get('code');
    const state = searchPara.get('state');
    useEffect(() =>
    {
        async function DoWork()
        {
            const results = await GetGeneric('/Authenticate/LinkSpotify?code=' + code + '&state=' + state);
            if (CheckApiError(navigate, results)) return;
            const newToken = await GetGeneric('/Authenticate/RefreshToken');
            if (CheckApiError(undefined, newToken)) return;
            localStorage.setItem("spot-token", newToken.token);
            setComplete(true);
        }
        DoWork();
    }, []);

    async function OpenWelcome()
    {
        navigate('/welcome');
    }

    if (!complete) {
        return <h2>Please Wait, processing</h2>
    }
    else {
        return <>
            <h2>Successfully connected to Spotify</h2>
            <button onClick={OpenWelcome} className='btn btn-primary'>Open Welcome Screen</button>
        </>
    }
}