import React from "react"
import {CheckApiError, DeleteGeneric, GetGeneric, GetLoggedInStatus} from "../common/teide";
import {useNavigate} from "react-router-dom";

export default function RemoveSpotifyLink()
{
    const currentLogin = GetLoggedInStatus();

    const navigate = useNavigate();

    async function RemoveAccess()
    {
        console.log("removing");
        const response = await DeleteGeneric(`/Authenticate/UnlinkSpotify`,null);
        console.log(response);
        if (CheckApiError(undefined,response)) return;

        const newToken = await GetGeneric('/Authenticate/RefreshToken');
        if (CheckApiError(undefined, newToken)) return;
        localStorage.setItem("spot-token", newToken.token);
        navigate('/welcome');
        navigate(0);
    }

    if (currentLogin.spotifyLinked) {
        return <>
            <br></br>
            <h2>You are currently linked to Spotify</h2>
            <br></br>
            <h6>Click the button below to remove access to Spotify for this account</h6>
            <br></br>
            <h6>To fully remove access to this application, you can go to your Spotify account, navigate to &apos;Security and privacy&apos; &gt;  &apos;Manage Apps&apos; from there, you can remove access to &apos;Let The Music Play&apos;</h6>
            <br></br>
            <button className="btn btn-primary" onClick={RemoveAccess}>Remove Spotify</button>
        </>
    }
    else return <>
    <h1>You aren&apos;t connected</h1>
    </>
}