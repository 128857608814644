import React, {useState} from 'react';

export interface ISongDetails
{
  track_id: string
  track_name: string
  artist_name: string
  album_art: string
  exclude: boolean
}

interface IProps{
  song: ISongDetails
}


export default function ProposedSong(props: IProps)
{
  const [exclude,setExclude]=useState<boolean>(false);
  function RemoveSong()
  {
    const newValue = !exclude;
    props.song.exclude=newValue;
    setExclude(newValue);
  }
  const className = `proposed-song${exclude?' excluded':''} row p-1 m-1`;
  const buttonText = exclude?'Re-Add':"Remove Item";
  return <div className={className} key={props.song.track_id}>
    <div className="col-1  my-auto"><img style={{width: "35px", height: "auto"}} loading="lazy" src={props.song.album_art} alt="" /></div>
    <div className='col-4 my-auto'>{props.song.track_name}</div>
    <div className='col-4 my-auto'>{props.song.artist_name}</div>
    <div className='col-1 my-auto'><input type='button' value={buttonText} onClick={RemoveSong} /></div>
  </div>
}
