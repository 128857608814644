import React, {useEffect, useRef} from "react";

import * as THREE from 'three';
//import {SVGLoader} from 'three/addons/loaders/SVGLoader.js';
import {SVGLoader, SVGResult} from 'three/examples/jsm/loaders/SVGLoader.js';

// interface IProps{
//     xxwidth: ()=>number
// }

export default function ThreeDWait(/*props : IProps*/)
{
    // const [width, setWidth] = useState(500);
    // const [height, setHeight] = useState(250);
    const container = useRef<HTMLDivElement>(null);
    useEffect(() =>
    {
        // const resizeObserver = new ResizeObserver((event) => {
        //     // Depending on the layout, you may need to swap inlineSize with blockSize
        //     // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
        //     setWidth(event[0].contentBoxSize[0].inlineSize);
        //     setHeight(event[0].contentBoxSize[0].blockSize);
        //     console.log(event[0]);
        //   });
        main();
        //resizeObserver.observe(container.current);
    }, []);
    return <div ref={container}></div>

    function main()
    {
        const scene = new THREE.Scene();
        scene.background = null;
        const useWidth=container.current!==null ? container.current?.clientWidth : 100;
        const size = {x: useWidth, y: useWidth};

        const camera = new THREE.PerspectiveCamera(45, size.x / size.y, 0.1, 1000);
        camera.position.z = 2;

        const renderer = new THREE.WebGLRenderer({alpha: true});
        renderer.setSize(size.x, size.y);
        if (container.current!==null)
        {
            //while (container.current.children.length>0) container.current.remove(container.current.firstChild);
            while (container.current.firstChild!==null) container.current.firstChild.remove(); //   container.current.removeChild(container.current.firstChild);
            container.current.appendChild(renderer.domElement);
        } 
        //renderer.domElement.background=null;
        const pi = Math.PI;
        const rotate = new THREE.Vector3(0, 0, pi);
        const rotateFlip = new THREE.Vector3(0, pi, pi);
        const hearts = 10;
        const music = 15;

        const clefItems = MySVGLoader('../svg/Treble_clef.svg', music, null, 0.00005, rotateFlip);
        const heartItems = MySVGLoader('../svg/A_perfect_SVG_heart.svg', hearts, 0xff0000, 0.001, rotate);
        const bassItems = MySVGLoader('../svg/bass_clef.svg', music, null, 0.0005, rotateFlip)
        const dieseItems = MySVGLoader('../svg/Dièse.svg', music, null, 0.13, null)

        const myContainer = new THREE.Group();
        const allRotation : THREE.Vector3[] = [];
        Promise.all([clefItems, heartItems, bassItems, dieseItems]).then(results =>
        {
            results.forEach(result => result.forEach(x => myContainer.add(x)))
            scene.add(myContainer);
            const directionalLight = new THREE.DirectionalLight(0xffffff, 3.5);
            directionalLight.position.set(0, 5, 5);
            scene.add(directionalLight);

            for (let c1 = 0; c1 < myContainer.children.length; c1++) {
                const floatRange = 0.05;
                const rot = new THREE.Vector3(RandomFloat(-floatRange, floatRange), RandomFloat(-floatRange, floatRange), RandomFloat(-floatRange, floatRange));
                allRotation.push(rot)
            }
            animate();
        })
        function animate()
        {
            if (container.current === null) 
            {
                // tidy up
                allRotation.length=0;
                myContainer.children.forEach(group=>{
                    group.children.forEach(obj=>{
                        const material = (obj as THREE.Mesh).material as THREE.Material;
                        material.dispose();
                    })
                });
                while (myContainer.children.length>0) myContainer.remove(myContainer.children[0]);
                return;
            }
            myContainer.children.forEach((x, idx) =>
            {
                x.position.z += (idx / 2000);
                if (x.position.z > camera.position.z) x.position.z = -5;
                x.rotation.y += allRotation[idx].y;
                x.rotation.z += allRotation[idx].z;
                x.rotation.x += allRotation[idx].x;
            });
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        }
        return renderer.domElement;
    }

    function RandomFloat(lowest: number, highest: number)
    {
        const ret = (Math.random() * (highest - lowest)) + lowest;
        return ret;
    }

    function MySVGLoader(filename: string, quantity: number, specificColour: number | null, scale: number, rotate: THREE.Vector3 | null)
    {
        let myResolve : (value: THREE.Group[])=>void;
        const loader = new SVGLoader();

        const myPromise = new Promise<THREE.Group[]>((resolve) =>
        {
            myResolve = resolve;
            loader.load(filename, LoadedSVG);
        });
        return myPromise;
        function RandomColour(lowest: number, highest: number)
        {
            const r = (Math.random() * (highest - lowest)) + lowest;
            const g = (Math.random() * (highest - lowest)) + lowest;
            const b = (Math.random() * (highest - lowest)) + lowest;
            return r*65536+(g*256)+b;
            //return new THREE.Color(r / 255, g / 255, b / 255);
        }

        type GroupItems = {
            grp: THREE.Group
            move: THREE.Vector3
            rotate: THREE.Vector3 | null
            origin: THREE.Vector3
        }

        function LoadedSVG(data : SVGResult)
        {
            const allGroups : GroupItems[] = [];
            for (let c1 = 0; c1 < quantity; c1++) {
                const group = new THREE.Group();
                const paths = data.paths;
                group.scale.multiplyScalar(scale);
                let useColour = specificColour;
                if (useColour == null) useColour = RandomColour(0, 255);

                for (let i = 0; i < paths.length; i++) {
                    const path = paths[i];
                    const material = new THREE.MeshStandardMaterial({
                        color: useColour,
                        side: THREE.DoubleSide,
                        depthWrite: true,
                        roughness: 0.5
                    });
                    const shapes = SVGLoader.createShapes(path);

                    for (let j = 0; j < shapes.length; j++) {
                        const shape = shapes[j];
                        const geometry = new THREE.ExtrudeGeometry(shape, {steps: 1, depth: 0.03 / scale, bevelEnabled: true});
                        const mesh = new THREE.Mesh(geometry, material);
                        geometry.dispose();
                        const box = new THREE.Box3().setFromObject(mesh);
                        box.getCenter(mesh.position); // this re-sets the mesh position
                        mesh.position.multiplyScalar(- 1);
                        group.add(mesh);
                    }
                }


                const movement = new THREE.Vector3(RandomFloat(-0.02, 0.02), RandomFloat(-0.02, 0.02), RandomFloat(-0.01, 0.05));
                const origin = new THREE.Vector3(RandomFloat(-1, 1), RandomFloat(-1, 1), RandomFloat(0, -5));
                allGroups.push({grp: group, move: movement, rotate: rotate, origin: origin});
            }
            const allItems : THREE.Group[] = [];
            allGroups.forEach(e =>
            {
                e.grp.position.copy(e.origin);
                if (e.rotate !== null) {
                    e.grp.rotateX(e.rotate.x);
                    e.grp.rotateY(e.rotate.y);
                    e.grp.rotateZ(e.rotate.z);
                }
                allItems.push(e.grp);
            })
            myResolve(allItems);
        }
    }

}