import React, {useState} from "react"
import '../css/Loading.css'
import ThreeDWait from "./ThreeDWait"

interface IProps{
    buttonText: string
    waitText: string
    className: string
    onClick: ()=>void;
    justButton? : boolean
    finishedAction?: 'hide' | 'revert'
    finishedText?: string
    use3dAnimation?: boolean
}

export default function PleaseWaitButton(props: IProps)
{
    const [running, setRunning] = useState<'idle' | 'running' | 'finished'>('idle');
    //const elementRef = useRef(null);

    // function GetSize() : number
    // {
    //     console.log(props.parentRef);
    //     if (props.parentRef.current===null) return 100;
    //     return props.parentRef.current.clientWidth;
    // }

    async function clicked()
    {
        setRunning('running');
        await props.onClick();
        if (props.finishedAction==='hide') setRunning('finished');
        else setRunning('idle');
    }

    if (running==='idle')
    {
        return <button className={props.className} onClick={clicked}>{props.buttonText}</button>
    }
    else if (running==='running')
    {
        if (props.justButton) return <button className='btn btn-secondary m-2'>{props.waitText}</button>
        if (props.use3dAnimation)
        {
            return <div>
            <h3>{props.waitText}</h3>
                <ThreeDWait></ThreeDWait>
                </div>
        }
        return <div className="loading">
            <p>{props.waitText}</p>
            <span><i></i><i></i><i></i></span>
        </div>
    }
    else if (running === 'finished')
    {
        const useFinished = props.finishedText !== undefined ? props.finishedText : "Finished";
        return <button className="btn btn-disabled m-2" disabled={true}>{useFinished}</button>
    }

}