import React, { useState } from "react";
import { CheckApiError, DeleteGeneric, GetGeneric, PostGeneric } from "../common/teide";
import StarRating from "./StarRating";
import Album from "./Album";
import "../css/Artist.css"

export interface ArtistSummary
{
    id: string,
    image: string,
    name: string,
    albums: AlbumSummary[]
    rating: number
    follower_count: number
    image_url: string
    artwork: number
}
export interface AlbumSummary
{
    id: string,
    type: string,
    name: string,
    image_url: string,
    release_date: string,
    total_tracks: number,
    is_playable: boolean,
    include: boolean,
    missing: boolean
    artists: IncludedArtist[]
}
export interface IncludedArtist
{
    id: string,
    name: string,
    type: string
}


export interface IProps{
    data: ArtistSummary
}

export async function UpdateRating(artistId: string, newRating: number)
{
    if (newRating === 4) 
    {
        await DeleteGeneric( '/Artists/StarRating?artistId=' + artistId,null);
    }
    else
    {
        const result = await PostGeneric('/Artists/StarRating?artistId=' + artistId + "&rating=" + newRating,null);
        if (CheckApiError(undefined,result))
        {
            alert('Unable to update');
        }
    }
}

export default function ArtistDetails(props: IProps)
{
    //const originalRating = props.data.rating ?? 4;
    const [currentStars,updateCurrentStars]=useState(props.data.rating);
    const [showAlbums,setShowAlbums] = useState(false);
    const [albumDetails,setAlbumDetails] = useState<AlbumSummary[]>();

    async function ChangeRating(newRating: number)
    {
        await UpdateRating(props.data.id,newRating);
        updateCurrentStars(newRating);
    }

    async function ShowAlbums()
    {
        setShowAlbums(!showAlbums);
        const useStars = currentStars === undefined ? 0 : currentStars;
        if (albumDetails === undefined)
        {
            const result = await GetGeneric('/Artists/GetAlbumsForArtist?artistId=' + props.data.id + "&artistName=" + props.data.name + "&artistRating=" + useStars);
            if (CheckApiError(undefined,result)) return;
            setAlbumDetails(result);
        }
    }

    let albumInfo =null;
    if (showAlbums && albumDetails!==undefined)
    {
        albumInfo = <div className="container ms-1 ms-lg-3">
        <div className="col">
            {
                albumDetails.map((elem: AlbumSummary) =>
                {
                    return <Album key={elem.id} album={elem}></Album>
                })
            }
        </div>
    </div>
    }

    let useTotal = null;
    if (props.data.follower_count !== undefined) useTotal = props.data.follower_count;
    return <><div className="row artist-row" key={props.data.id} id={props.data.id}>
        <div className="artist-cover col-auto my-auto" ><img alt="" src={props.data.image_url} ></img></div>
        <div className="artist-name col col-lg my-auto">{props.data.name}</div>
        <div className="artist-artwork-count col-1 my-auto d-none d-lg-block">{props.data.artwork}</div>
        <div className="artist-follower-count col-1 my-auto d-none d-lg-block">{useTotal}</div>
        <div className="artist-stars col-4 col-lg-3 my-auto text-center"><StarRating starCount={7} stars={currentStars} onRatingChanged={ChangeRating} /></div>
        <button onClick={ShowAlbums} className="artist-albums col-1 my-auto btn btn-primary btn-sm" style={{paddingTop: '0px',paddingBottom: '0px'}}>...</button>
    </div>
    
    {albumInfo}
    </>
}