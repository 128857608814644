import React, {useEffect} from "react";
import {useRef} from 'react';
import {CheckApiError, PostGeneric} from '../common/teide';
import {useNavigate} from "react-router-dom";


export default function Register()
{
    useEffect(()=>{
        if (emailPrompt.current!==null) emailPrompt.current.focus();
    },[])

    const errorArea = useRef<HTMLSpanElement>(null);

    const emailPrompt = useRef<HTMLInputElement>(null);
    // const newPass1 = useRef<HTMLInputElement>(null);
    // const newPass2 = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    async function CreateUser(e: React.MouseEvent<HTMLFormElement, MouseEvent>)
    {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const email = formData.get('email-su');
        const pass1 = formData.get('pass1');
        const pass2 = formData.get('pass2');
        if (pass1!==pass2)
        {
            if (errorArea.current!==null) errorArea.current.innerText="Passwords don't match";
            return;
        }
        const creds = {username: email, password: pass1};
        const result = await PostGeneric('/Authenticate/RegisterUser', JSON.stringify(creds));
        console.log(result);
        CheckApiError(navigate, result);
        localStorage.setItem("spot-token", result.token);
        navigate('/welcome');
    }

    return <div className="row">
    <div className="col"></div>
    <div className="container-flow login-wrap">
        <div className="row">
            <h3 className='header text-center'>Please register</h3>
        </div>
        <form className="sign-in-htm" onSubmit={CreateUser} noValidate>
            <div className="group">
                <label htmlFor="email-su" className="label">Email Address</label>
                <input name="email-su" id="email-su" ref={emailPrompt} type="email" className="input" autoComplete='username' />
            </div>
            <div className="group">
                <label htmlFor="pass1" className="label">Password</label>
                <input name="pass1" id="pass1" type="password" className="input" autoComplete='new-password' />
            </div>
            <div className="group">
                <label htmlFor="pass2" className="label">Repeat Password</label>
                <input name="pass2" id="pass2" type="password" className="input" autoComplete='new-password' />
            </div>
            <div className="group">
                <input type="submit" className="btn btn-primary" value="Register" />
            </div>
            <div className="hr"></div>
            <div className="group">
                <span className='header red' ref={errorArea}></span>
            </div>
        </form>
    </div>
    <div className="col"></div>
    </div>
}