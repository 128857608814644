import React from "react";
import SearchBox from "./SearchBox";

export interface IGenreChange
{
    action: 'add' | 'remove'
    artistId: string
    genreName: string
}

interface IProps
{
    genreName: string,
    artistIds: string[]
    artistLookup: {[k: string]: string}
    onChange: (change: IGenreChange) => void;
}

export default function UserGenreLine(props: IProps)
{
    async function ItemAdded(artistId: string)
    {
        props.onChange({action:'add',artistId:artistId,genreName: props.genreName});
    }
    async function ItemRemoved(artistId: string)
    {
        props.onChange({action:'remove',artistId:artistId,genreName: props.genreName});
    }

    return <div className="row" style={{color: 'black', backgroundColor: 'white', margin: '8px', padding: '5px'}}>
        <div className="col-2">{props.genreName}</div>
        <div className="col-10"><SearchBox searchText="Search Artists.." removeItem={ItemRemoved} addItem={ItemAdded} selected={props.artistIds} lookup={props.artistLookup}></SearchBox></div>
    </div>
}