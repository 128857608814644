import React from "react"
import {CheckApiError, GetGeneric, urlPrefix} from "../common/teide"
import {useNavigate} from "react-router-dom";
import PleaseWaitButton from "../components/PleaseWaitButton";

export default function PictureGrid()
{
    const navigate = useNavigate();

    return <><div className="d">
        <br></br>
        <PleaseWaitButton buttonText="Produce Artwork" className="btn btn-primary" onClick={ProduceArtwork} waitText="Working, please wait" use3dAnimation={true}></PleaseWaitButton>
        <br></br>
        <br></br>
        <PleaseWaitButton onClick={ProduceVR} className="btn btn-primary" waitText="Working, please wait" buttonText="Produce VR"></PleaseWaitButton>
    </div>
    </>

    async function ProduceArtwork()
    {
        const results = await GetGeneric('/Artwork/GenerateArtworkImage');
        if (CheckApiError(navigate, results)) return;
        if (results.resource==='') return;
        const url = urlPrefix+"/ResourceProvider/Get?resourceId="+results.resource;
        window.open(url,results.resource);
    }

    async function ProduceVR()
    {
        const results = await GetGeneric('/Artwork/Generate360View');
        if (CheckApiError(navigate, results)) return;
        window.open(urlPrefix+"/ResourceProvider/Get?resourceId="+results.resource,"LTMP VR");
    }


}