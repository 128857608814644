import React from "react"

interface IProps
{
    title: string
    color?: string
    info: string
    icon: ProgressIcons
}

export type ProgressIcons = 'check' | 'x' | 'emoji-neutral'


export default function ProgressIndicator(props: IProps)
{
    let useColor;
    if (props.color === undefined) {
        if (props.icon === 'check') useColor = 'green';
        if (props.icon === 'x') useColor = 'red';
        if (props.icon === 'emoji-neutral') useColor = 'orange';
    }
    return <div className="circle" style={{borderColor: useColor}}>
        <div className="circle-text">{props.title}</div>
        <div className="circle-info">{props.info}</div>
        <div className={"circle-tick bi bi-" + props.icon}></div>
    </div>
}