import React, {useState} from 'react';
import './css/Colours.css';
import './css/App.css';
import './css/Dialog.css';
import './css/Menus.css';
import PlaylistList from './pages/PlaylistList';
import ArtistList from './pages/ArtistList';
import SideMenu from './menus/SideMenu';
import LinkSpotify from './pages/LinkSpotify';
import Recommended from './pages/Recommended';
import Welcome from './pages/Welcome';
import {Route, Routes} from 'react-router-dom';
import ConfirmLink from './pages/ConfirmSpotify';
import ErrorInfo from './pages/ErrorInfo';
import Login from './pages/Login';
import Register from './pages/Register';
import CreatePlaylist from './pages/CreatePlaylist';
import PictureGrid from './pages/PictureGrid';
import RemoveSpotifyLink from './pages/RemoveSpotifyLink';
import ProposedPlaylist from './pages/ProposedPlaylist';
import GenreManagement from './pages/GenreManagement';
function App()
{
  const [mobileMenuOpen,setMobileMenuOpen] = useState<boolean>(false);

  function MenuClicked()
  {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  return (
    <>
    <div className="App">
      <div className='container-fluid'>
        <div className='row header-row'>
          <div className='col mx-auto logo-header'>
            <div className='row no-gutters p-0'>
            <div className='d-lg-none menu-icon col-auto bi bi-list p-3 h1' onClick={MenuClicked}></div>
            <div className='col'><img className='logo-image' alt='logo' src="/LTMPLogo.png" ></img></div>
            </div>
            {/* <div className='logo-image'>Hello</div> */}
          </div>
        </div>
        <div className='row main-content'>
          <div className='col-auto menu-container d-none d-lg-block'><SideMenu toggleMenu={false} itemSelected={()=>setMobileMenuOpen(false)}></SideMenu></div>
          {mobileMenuOpen ? <div className='col-auto menu-container p-0 m-0'><SideMenu toggleMenu={true} itemSelected={()=>setMobileMenuOpen(false)}></SideMenu></div> : undefined}
          <div className='col main-column'>
            <Routes>
              <Route path='/error' element=<ErrorInfo></ErrorInfo> />
              <Route path='/confirm-spotify' element=<ConfirmLink></ConfirmLink> />
              <Route path='/artists' element=<ArtistList></ArtistList> />
              <Route path='/playlists' element=<PlaylistList></PlaylistList> />
              <Route path='/recommended' element=<Recommended></Recommended> />
              <Route path='/gen-playlist' element=<CreatePlaylist></CreatePlaylist> />
              <Route path='/login' element=<Login></Login> />
              <Route path='/register' element=<Register></Register> />
              <Route path='/link-spotify' element=<LinkSpotify></LinkSpotify> />
              <Route path='/unlink-spotify' element=<RemoveSpotifyLink></RemoveSpotifyLink> />
              <Route path='/welcome' element=<Welcome></Welcome> />
              <Route path='/artwork' element=<PictureGrid></PictureGrid> />
              <Route path='/proposed-playlist' element=<ProposedPlaylist></ProposedPlaylist> />
              <Route path='/genre-management' element=<GenreManagement></GenreManagement> />
              <Route path='/' element=<Welcome></Welcome> />
            </Routes>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default App;
