import React, {useState} from "react";
import {GetLoggedInStatus} from "../common/teide";
import {useNavigate} from "react-router-dom";

interface IProps{
	toggleMenu: boolean
	itemSelected: ()=>void;
}

export default function SideMenu(props: IProps)
{
	const navigate = useNavigate();
	const [current, setCurrent] = useState<string>();

	function changePage(e: React.MouseEvent)
	{
		props.itemSelected(); // ensure that the parent knows this, and will close the menu if it's a mobile
		const target = e.currentTarget as HTMLElement;
		const newPage = target.id;
		if (newPage === 'logout') {
			localStorage.removeItem('spot-token');
			navigate('/welcome');
			navigate(0);
			return;
		}
		setCurrent(newPage);
		navigate(target.id);
	}

	function GetLine(id: string, text: string, icon: string)
	{
		const useClass = (current == id) ? "selected" : "";
		return <div className={useClass} id={id} onClick={changePage}>
			<i className={'pe-3 side-icon h3 bi bi-' + icon}></i>
			<span className="">{text}</span>
		</div>
	}
	const mainClass= props.toggleMenu ? "leftbar position-absolute p-1 m-1" : "leftbar";
	const loggedInStatus = GetLoggedInStatus();
	if (loggedInStatus.loggedIn) {
		return <div className={mainClass}>
			<div>
				{GetLine('welcome', 'Home/Welcome', 'house-door-fill')}
				{loggedInStatus.spotifyLinked ? GetLine('artists', 'Your Followed Artists', 'person-hearts') : undefined}
				{loggedInStatus.spotifyLinked ? GetLine('playlists', 'Playlists', 'list-task') : undefined}
				{loggedInStatus.spotifyLinked ? GetLine('recommended', 'Recommended Artists', 'magic') : undefined}
				{loggedInStatus.spotifyLinked ? GetLine('gen-playlist', 'Generate Playlist', 'cpu-fill') : undefined}
				{loggedInStatus.spotifyLinked ? GetLine('artwork', 'Artwork Extras', 'image') : undefined}
				{loggedInStatus.spotifyLinked ? GetLine('genre-management', 'User-Defined Genres', 'boombox') : undefined}
			</div>
			<hr></hr>
			<div className="lower-buttons" style={{paddingTop: "0px"}}>
			{GetLine('link-spotify', 'Link To Spotify', 'ethernet')}
			{GetLine('unlink-spotify', 'Unlink Spotify', 'x-octagon')}
			{GetLine('logout', 'Log Out', 'box-arrow-left')}
			</div>
		</div>
	}
	else {
		return <div className={mainClass}>
			<div>
			{GetLine('welcome', 'Home/Welcome', 'house-door-fill')}
			</div>
			<hr></hr>
			<div>
				{GetLine('register', 'Register New User', 'person-plus')}
				{GetLine("login", "Login", 'box-arrow-right')}
			</div>
		</div>
	}
}

