import React, {useEffect} from "react";
import {useRef} from 'react';
import {PostGeneric} from '../common/teide';
import '../css/Security.css';
import {useNavigate} from 'react-router-dom';

export default function Login()
{
    useEffect(() =>
    {
        if (emailPrompt.current !== null) emailPrompt.current.focus();
    }, [])

    const emailPrompt = useRef<HTMLInputElement>(null);

    const errorArea = useRef<HTMLSpanElement>(null);

    const navigate = useNavigate();

    async function LoginUser(e: React.MouseEvent<HTMLFormElement, MouseEvent>)
    {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const creds = {username: formData.get('email'), password: formData.get('pass')};
        const result = await PostGeneric('/Authenticate/GetToken', JSON.stringify(creds));
        if (result.status !== undefined && errorArea.current!==null) {
            if (result.status === 401) {
                errorArea.current.innerText = "Invalid Login, username or password not recognised";
            }
        }
        if (result.token !== undefined) {
            localStorage.setItem("spot-token", result.token);
            navigate('/welcome');
        }
    }

    return <div className="row">
        <div className="col"></div>
        <div className="container-flow login-wrap">
            <div className="row">
                <div className='header text-center'>Please log in to LetTheMusicPlay</div>
            </div>
            <br></br>
            <div className="row">
                <div className='green header text-center'>This is NOT your Spotify login details</div>
            </div>
            <br></br>

            <div className="">
                <form className="sign-in-htm" onSubmit={LoginUser} noValidate>
                    <div className="group">
                        <label htmlFor="user" className="label">Email Address</label>
                        <input ref={emailPrompt} name="email" id="email" type="email" className="input" autoComplete='username' />
                    </div>
                    <div className="group">
                        <label htmlFor="pass" className="label">Password</label>
                        <input name="pass" id="pass" type="password" className="input" autoComplete='current-password' />
                    </div>
                    <div className="group">
                        <input type="submit" className="btn btn-primary" value="Sign In" />
                    </div>
                    <div className="hr"></div>
                    <div className="foot-lnk">
                        <a href="#forgot">Forgot Password?</a>
                    </div>
                    <div className="group">
                        <span className='header red' ref={errorArea}></span>
                    </div>

                </form>
            </div>
        </div>
        <div className="col"></div>
    </div>
}