import React, {useEffect, useRef} from "react";

import {DecodeToken, ExpandURL, ToBase64} from "../common/teide";

function GenerateURL(userIdentifier: string)
{
    const scopes = [
        // "user-modify-playback-state",
        // "user-read-playback-state",
        // "user-read-currently-playing",
        "user-follow-modify",
        "user-follow-read",
        "user-library-modify",
        "user-library-read",
        "user-top-read",
        "playlist-modify-public",
        "user-read-recently-played",
        // "user-read-playback-position",
        "user-top-read",
        "playlist-read-collaborative",
        "playlist-modify-public",
        "playlist-read-private",
        "playlist-modify-private",
        //"app-remote-control",
        //"streaming",
        "user-read-email",
        "user-read-private",
        "user-library-modify",
        "user-library-read"
    ];

    const client_id = 'f55a38c0c0c04bd7a0560c90fcf2e333';
    const parameters = {
        "client_id": client_id,
        "response_type": "code",
        //"redirect_uri": "http://spotify.viewsfrom.co.uk:778/test.php",
        'redirect_uri': process.env.REACT_APP_SPOTIFY_LINK,
        "state": userIdentifier,
        "scope": scopes.join(',')
    }
    const auth_url = ExpandURL("https://accounts.spotify.com/authorize", parameters);
    return auth_url;
}

export default function LinkSpotify()
{
    useEffect(()=>{
        if (urlContent.current==null) return;
        urlContent.current.select();
        urlContent.current.setSelectionRange(0,9999);
        urlContent.current.focus();
    },[])

    const urlContent = useRef<HTMLInputElement>(null);
    function CopyClipboard()
    {
        if (urlContent.current==null) return;
        if (!window.isSecureContext) 
        {
            alert('You need to run this from a secure browser');
            return;
        }
        navigator.clipboard.writeText(urlContent.current.value);
    }

    function OpenLink()
    {
        window.location.replace(url);
    }

    const token = localStorage.getItem("spot-token");
    const tokenInfo = DecodeToken(token);
    if (tokenInfo === null) return "";
    const username = tokenInfo.sub;
    const b64Username = ToBase64(username);
    const url = GenerateURL(b64Username);
    
    if (tokenInfo.spot_info == '1') {
        return <>
            <br></br>
            <h2>You have already linked your account to Spotify</h2>
            <h6>If you need to re-authorize (maybe with a different Spotify account) you can click the button below to copy the URL into the clipboard, and paste it into another browser window</h6>
            <br></br>
            <div><input readOnly size={30} ref={urlContent} type="readonly" value={url}></input></div>
            <br></br>
            <button key={url} className="btn btn-primary" onClick={CopyClipboard}>Copy Link To Clipboard</button>
        </>
    }
    return <div>
            <br></br>
            <h2>Link To Spotify</h2>
            <h6>Click the link below to approve access, this will allow you to view your playlist and artists</h6>
            <br></br>
            <button className="btn btn-primary" onClick={OpenLink}>Link To Spotify</button>
            <br></br>
            <br></br>
            <br></br>
            <h6>Alternatively, you can copy this link and paste it into another browser window</h6>
            <div><input readOnly size={30} ref={urlContent} type="readonly" value={url}></input></div>
            <br></br>
            <button className="btn btn-primary" onClick={CopyClipboard}>Copy Link To Clipboard</button>
    </div>
}