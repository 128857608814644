import React, {useState} from "react";

interface IProps{
    starCount: number
    stars: number
    onRatingChanged: (newRating: number)=>void
}

export default function StarRating(props: IProps)
{
    const [isModified,setIsModified] = useState<boolean>(false);
    const [currentStars,setCurrentStars] = useState<number>(props.stars);
    function RatingChanged(e: React.MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        setIsModified(true);
        const rating=Number(e.currentTarget.getAttribute("data-rating"))
        setCurrentStars(rating);
        props.onRatingChanged(rating);
    }
    const dict=[];
    for (let c1=0 ; c1<props.starCount ; c1++)
    {
        if (currentStars>c1 ) dict.push(<span className="star-good" key={c1} data-rating={c1+1} data-key={c1} onClick={RatingChanged}>★</span>);
        else dict.push(<span className="star-bad" key={c1} data-rating={c1+1} onClick={RatingChanged}>★</span>)
    }
    let useClassName="starrating" + (isModified?" modified":"");
    if (currentStars === 0 || currentStars === undefined)
    {
        useClassName="starrating no-stars";
    }
    return <span className={useClassName}>{dict}</span>
}