import React from "react";
import {useState} from "react";
import {DeleteGeneric, PostGeneric} from "../common/teide";
import {AlbumSummary} from "./ArtistDetails";

interface IProps
{
    album: AlbumSummary
}

export default function Album(props: IProps)
{
    const [selectArtWork, setSelectArtwork] = useState(props.album.include !== undefined);

    async function IncludeArtwork()
    {
        if (!selectArtWork) // if it's not already set
        {
            const artistId = props.album.artists.length==0?'':props.album.artists[0].id;
            await PostGeneric(`/Album/${props.album.id}/IncludeAlbumArtwork?artistId=${artistId}`,null);
            setSelectArtwork(true);
        }
        else {
            await DeleteGeneric(`/Album/${props.album.id}/IncludeAlbumArtwork`,null);
            setSelectArtwork(false);
        }
    }
    const theTick= selectArtWork ? <span>✔</span> : <></>;
    return <div className="album-info row m-1 flex-nowrap" key={props.album.id} onClick={IncludeArtwork}>
        <div className="artist-cover col-1 p-0"><img loading="lazy" src={props.album.image_url} alt={props.album.name} /></div>
        <div className="col-7 my-auto">{props.album.name}</div>
        <div className="col-2 my-auto d-lg-none">{props.album.release_date.substring(0,4)}</div>
        <div className="col-2 my-auto d-none d-lg-flex">{props.album.release_date}</div>
        <div className="col-1 my-auto">{props.album.total_tracks}</div>
        <div className="col-1 my-auto p-0">{theTick}</div>
   </div>;
}