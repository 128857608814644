import React, {CSSProperties, useState} from "react";

interface IProps
{
    searchText: string
    selected: string[]
    lookup: {[k: string]: string}
    addItem: (addedKey: string) => void
    removeItem: (removedKey: string) => void
}

export default function SearchBox(props: IProps)
{
    const [matches, setMatches] = useState<[string, string][]>();
    const [activeItem, setActiveItem] = useState<number>(-1);
    const [selectedItems, setSelectedItems] = useState<string[]>(props.selected);
    function textChanged(e: React.ChangeEvent<HTMLInputElement>)
    {
        const artistMatches: [string, string][] = []
        if (e.currentTarget.value.length > 1) {
            Object.entries(props.lookup).forEach(artist =>
            {
                if (artistMatches.length > 10) return;
                if (artist[1].toLowerCase().includes(e.currentTarget.value.toLowerCase())) artistMatches.push(artist);
            });
        }
        setActiveItem(-1);
        setMatches(artistMatches);
    }

    function keyPressed(e: React.KeyboardEvent<HTMLInputElement>)
    {
        if (matches == undefined) return;
        if (e.code === 'ArrowDown') {
            if (activeItem < (matches.length - 1)) setActiveItem(activeItem + 1);
        }
        else if (e.code === 'ArrowUp') {
            if (activeItem > 0) setActiveItem(activeItem - 1);
        }
        else if (e.code === 'Enter') {
            addMain(matches[activeItem][0])
        }
        else if (e.code === 'Escape') {
            setActiveItem(-1);
            setMatches([]);
        }
    }
    function clickItem(e: React.MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        addMain(e.currentTarget.id);
    }

    function addMain(id: string)
    {
        selectedItems.push(id);
        setSelectedItems(selectedItems);
        props.addItem(id);
        setActiveItem(-1);
    }

    function removeMain(id: string)
    {
        const newList = selectedItems.filter(x=>x!==id);
        setSelectedItems(newList);
        props.removeItem(id);
    }

    interface ISelectedItem
    {
        id: string;
    }
    function SelectedItem(selprops: ISelectedItem)
    {
        function removeItem()
        {
            removeMain(selprops.id);
        }
        return <div className="search-item">
            {props.lookup[selprops.id]}
            <button onClick={removeItem}>X</button>
        </div>
    }

    const currentSelection = selectedItems.map(a => <SelectedItem id={a} key={a} ></SelectedItem>);
    const dropItems = matches?.map((m, idx) =>
    {
        const className = "dropdown-item" + ((activeItem == idx) ? ' active' : '') + ((selectedItems.includes(m[0])) ? ' disabled' : '');
        return <div className={className} id={m[0]} key={m[0]} onClick={clickItem}>{m[1]}</div>
    })
    const useStyle: CSSProperties = (matches !== undefined && matches.length > 0) ? {display: "block"} : {};
    return <div className="row">
        <div className="col-auto">
            {currentSelection}
            <div className="search-item search-item-prompt" >
                <div className="dropdown">
                    <input placeholder={props.searchText} onChange={textChanged} onKeyUp={keyPressed}></input>
                    <div className="dropdown-menu" style={useStyle}>
                        {dropItems}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

